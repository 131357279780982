/*
Author: Aftab Rehan (aftab.rehan@syncclouds.com)
Created: 21/08/2024, 15:28:38
Modified: 13/09/2024, 11:23:56

Description:
  This file defines constants based on environment variables and exports them for use throughout the application.
*/

export const VITE_GOOGLE_CLIENT_ID = import.meta.env.VITE_GOOGLE_CLIENT_ID || ''
export const GOOGLE_CLIENT_SECRET = import.meta.env.GOOGLE_CLIENT_SECRET || ''
export const VITE_GOOGLE_PLACE_MAP_ID =
  import.meta.env.VITE_GOOGLE_PLACE_MAP_ID || ''
export const VITE_SENTRY_URL = import.meta.env.VITE_SENTRY_URL || ''
export const VITE_DEBUG_SENTRY = !import.meta.env.DEV
export const VITE_PUBLISHABLE_API_KEY =
  import.meta.env.VITE_PUBLISHABLE_API_KEY || ''
export const VITE_CHANNEX_API_KEY = import.meta.env.VITE_CHANNEX_API_KEY || ''
export const VITE_LOCAL_HOST_URL = import.meta.env.VITE_LOCAL_HOST_URL || ''
