import { createSlice, PayloadAction, createAsyncThunk } from '@reduxjs/toolkit'
import axios from 'axios'
import { SLICE_GETRESERVATION_TYPE } from './constant'
import appConfig from '@/configs/app.config'
import { getLocale } from '@/utils/locale'
import { getAccessToken } from '@/utils/accessToken'

export type GetReservationTypeState = {
  currentRouteKey: string
  apiData: any[]
  status: 'idle' | 'loading' | 'succeeded' | 'failed'
  error: string | null
}

export const initialGetReservationTypeState: GetReservationTypeState = {
  currentRouteKey: '',
  apiData: [],
  status: 'idle',
  error: null
}

// Define an async thunk for fetching API data
export const fetchGetReservationTypeApiData = createAsyncThunk(
  `${SLICE_GETRESERVATION_TYPE}/fetchGetReservationTypeApiData`,
  async (_, thunkAPI) => {
    const accessToken = getAccessToken(thunkAPI)

    const config = {
      url: `lookups/get-reservation-type/`,
      method: 'get',
      baseURL: `${appConfig.reservationApiPrefix}`,
      headers: {
        'accept-language': getLocale(),
        'content-type': 'application/json',
        Authorization: `Bearer ${accessToken}`
      }
    }

    const response = await axios(config)
    return response.data
  }
)

export const getReservationTypeSlice = createSlice({
  name: `${SLICE_GETRESERVATION_TYPE}/getReservationType`,
  initialState: initialGetReservationTypeState,
  reducers: {
    setCurrentGetReservationTypeRouteKey: (
      state,
      action: PayloadAction<string>
    ) => {
      state.currentRouteKey = action.payload
    }
  },
  extraReducers: builder => {
    builder
      .addCase(fetchGetReservationTypeApiData.pending, state => {
        state.status = 'loading'
      })
      .addCase(fetchGetReservationTypeApiData.fulfilled, (state, action) => {
        state.status = 'succeeded'
        state.apiData = action.payload
      })
      .addCase(fetchGetReservationTypeApiData.rejected, (state, action) => {
        state.status = 'failed'
        state.error = action.error.message || null
      })
  }
})

export const { setCurrentGetReservationTypeRouteKey } =
  getReservationTypeSlice.actions

export default getReservationTypeSlice.reducer
