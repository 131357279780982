import { createSlice, PayloadAction, createAsyncThunk } from '@reduxjs/toolkit'
import axios from 'axios'
import { GET_UNIT_DATA_BASE_NAME } from './constants'
import appConfig from '@/configs/app.config'
import { getLocale } from '@/utils/locale'
import { getAccessToken } from '@/utils/accessToken'

export type UnitDataState = {
  currentRouteKey: string
  apiData: any[]
  status: 'idle' | 'loading' | 'succeeded' | 'failed'
  error: string | null
}

export const initialUnitDataState: UnitDataState = {
  currentRouteKey: '',
  apiData: [],
  status: 'idle',
  error: null
}
// Define an async thunk for fetching API data
export const fetchUnitDataApiData = createAsyncThunk(
  `${GET_UNIT_DATA_BASE_NAME}/fetchUnitDataApiData`,
  async ({ id, amenity }: { id: any; amenity?: boolean }, thunkAPI) => {
    if (!id) return

    const accessToken = getAccessToken(thunkAPI)

    const url = `room/get-property-room/?property_id=${id}`
    const amenityUrl = `room/get-property-room/?property_id=${id}&amenities=${amenity}`

    const config = {
      url: amenity ? amenityUrl : url,
      method: 'get',
      baseURL: `${appConfig.propertyApiPrefix}`,
      headers: {
        'accept-language': getLocale(),
        'content-type': 'application/json',
        Authorization: `Bearer ${accessToken}`
      }
    }

    const response = await axios(config)
    return response.data
  }
)
export const getUnitDataSlice = createSlice({
  name: `${GET_UNIT_DATA_BASE_NAME}/getUnitData`,
  initialState: initialUnitDataState,
  reducers: {
    setUnitDataRouteKey: (state, action: PayloadAction<string>) => {
      state.currentRouteKey = action.payload
    }
  },
  extraReducers: builder => {
    builder
      .addCase(fetchUnitDataApiData.pending, state => {
        state.status = 'loading'
      })
      .addCase(fetchUnitDataApiData.fulfilled, (state, action) => {
        state.status = 'succeeded'
        state.apiData = action.payload
      })
      .addCase(fetchUnitDataApiData.rejected, (state, action) => {
        state.status = 'failed'
        state.error = action.error.message || null
      })
  }
})
export const { setUnitDataRouteKey } = getUnitDataSlice.actions

export default getUnitDataSlice.reducer
