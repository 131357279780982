/*
Author: Aftab Rehan (aftab.rehan@syncclouds.com)
Created: 19/08/2024, 14:55:22
Modified: 19/08/2024, 14:55:53

Description:
  This file defines and exports the root reducer for the Redux store. It combines static reducers from various slices (e.g., auth, base, theme) with any dynamic reducers passed at runtime. It uses `combineReducers` from Redux to create a combined reducer function that handles the application state and action processing. The root reducer also integrates the RTK Query service reducer.
*/

import { combineReducers, CombinedState, AnyAction, Reducer } from 'redux'

import RtkQueryService from '@/services/RtkQueryServices'
import auth, { AuthState } from './slices/auth'
import base, { BaseState } from './slices/base'
import theme, { ThemeState } from './slices/theme/themeSlice'
import properties, { propertyManageState } from './slices/properties'
import reservation, { ReservationState } from './slices/Reservation'
import selectedProperty, {
  selectedPropertyState
} from './slices/properties/selectedProperty'
import payment, { PaymentManageState } from './slices/Payments'

export type RootState = CombinedState<{
  auth: CombinedState<AuthState>
  base: CombinedState<BaseState>
  properties: CombinedState<propertyManageState>
  reservation: CombinedState<ReservationState>
  payment: CombinedState<PaymentManageState>
  theme: ThemeState
  selectedProperty: selectedPropertyState
  [RtkQueryService.reducerPath]: any
}>

export interface AsyncReducers {
  [key: string]: Reducer<any, AnyAction>
}

const staticReducers = {
  auth,
  base,
  theme,
  properties,
  reservation,
  selectedProperty,
  payment,
  [RtkQueryService.reducerPath]: RtkQueryService.reducer
}

const rootReducer =
  (asyncReducers?: AsyncReducers) => (state: RootState, action: AnyAction) => {
    const combinedReducer = combineReducers({
      ...staticReducers,
      ...asyncReducers
    })
    return combinedReducer(state, action)
  }

export default rootReducer
