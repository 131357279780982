import { combineReducers } from '@reduxjs/toolkit'
import session, { SessionState } from './sessionSlice'
import user, { UserState } from './userSlice'
import role, { roleState } from './RoleSlice'
import permission, { permissionState } from './getPermisionsSlice'
import section, { sectionState } from './sectionPermSlice'
import member, { memberState } from './getMembersSlice'
import language, { languageState } from './GetLanguage'
import dashboard, { dashboardState } from './Dashboard'
import device, { deviceState } from './GetDevicesSlice'
import getModules, { getModulesState } from './GetModulesSlice'

const reducer = combineReducers({
  session,
  user,
  role,
  permission,
  section,
  member,
  language,
  dashboard,
  device,
  getModules
})
export type AuthState = {
  session: SessionState
  user: UserState
  role: roleState
  permission: permissionState
  section: sectionState
  member: memberState
  language: languageState
  dashboard: dashboardState
  device: deviceState
  getModules: getModulesState
}
export * from './sectionPermSlice'
export * from './userSlice'
export * from './RoleSlice'
export * from './getPermisionsSlice'
export * from './getMembersSlice'
export * from './GetLanguage'
export * from './Dashboard'
export * from './GetDevicesSlice'
export * from './GetModulesSlice'
export default reducer
