import { createSlice, PayloadAction, createAsyncThunk } from '@reduxjs/toolkit'
import axios from 'axios'
import { GET_PROMOCODE } from './constant'
import appConfig from '@/configs/app.config'
import { getLocale } from '@/utils/locale'
import { getAccessToken } from '@/utils/accessToken'

export type GetPromocodeState = {
  currentRouteKey: string
  apiData: any[]
  status: 'idle' | 'loading' | 'succeeded' | 'failed'
  error: string | null
}

export const initialGetPromocodeState: GetPromocodeState = {
  currentRouteKey: '',
  apiData: [],
  status: 'idle',
  error: null
}
// Define an async thunk for fetching API data
export const fetchGetPromocodeTypeApiData = createAsyncThunk(
  `${GET_PROMOCODE}/fetchGetPromocodeTypeApiData`,
  async (prop_id: any, thunkAPI) => {
    const accessToken = getAccessToken(thunkAPI)

    const config = {
      url: `booking-engine/get-promocode/?property_id=${prop_id}`,
      method: 'get',
      baseURL: `${appConfig.reservationApiPrefix}`,
      headers: {
        'accept-language': getLocale(),
        'content-type': 'application/json',
        Authorization: `Bearer ${accessToken}`
      }
    }

    const response = await axios(config)
    return response.data
  }
)

export const GetPromocodeTypeSlice = createSlice({
  name: `${GET_PROMOCODE}/getPromocode`,
  initialState: initialGetPromocodeState,
  reducers: {
    setCurrentGetPromocodeRouteKey: (state, action: PayloadAction<string>) => {
      state.currentRouteKey = action.payload
    }
  },
  extraReducers: builder => {
    builder
      .addCase(fetchGetPromocodeTypeApiData.pending, state => {
        state.status = 'loading'
      })
      .addCase(fetchGetPromocodeTypeApiData.fulfilled, (state, action) => {
        state.status = 'succeeded'
        state.apiData = action.payload
      })
      .addCase(fetchGetPromocodeTypeApiData.rejected, (state, action) => {
        state.status = 'failed'
        state.error = action.error.message || null
      })
  }
})

export const { setCurrentGetPromocodeRouteKey } = GetPromocodeTypeSlice.actions

export default GetPromocodeTypeSlice.reducer
