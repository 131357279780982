import { createSlice, PayloadAction, createAsyncThunk } from '@reduxjs/toolkit'
import axios from 'axios'
import { SLICE_PERM_NAME } from './constants'
import appConfig from '@/configs/app.config'
import { getLocale } from '@/utils/locale'
import { getAccessToken } from '@/utils/accessToken'
export type permissionState = {
  currentRoutePermKey: string
  apiData: any[]
  status: 'idle' | 'loading' | 'succeeded' | 'failed'
  error: string | null
}
export const initialPermState: permissionState = {
  currentRoutePermKey: '',
  apiData: [],
  status: 'idle',
  error: null
}
// Define an async thunk for fetching API data
export const fetchApiPermissionData = createAsyncThunk(
  `${SLICE_PERM_NAME}/fetchApiPermissionData`,
  async (_, thunkAPI) => {
    const accessToken = getAccessToken(thunkAPI)

    const config = {
      url: 'auth/get-all-permission/',
      method: 'get',
      baseURL: `${appConfig.authApiPrefix}`,
      headers: {
        'accept-language': getLocale(),
        'content-type': 'application/json',
        Authorization: `Bearer ${accessToken}`
      }
    }

    const response = await axios(config)
    return response.data
  }
)
export const permissionSlice = createSlice({
  name: `${SLICE_PERM_NAME}/perms`,
  initialState: initialPermState,
  reducers: {
    setCurrentRoutePermKey: (state, action: PayloadAction<string>) => {
      state.currentRoutePermKey = action.payload
    }
  },
  extraReducers: builder => {
    builder
      .addCase(fetchApiPermissionData.pending, state => {
        state.status = 'loading'
      })
      .addCase(fetchApiPermissionData.fulfilled, (state, action) => {
        state.status = 'succeeded'
        state.apiData = action.payload
      })
      .addCase(fetchApiPermissionData.rejected, (state, action) => {
        state.status = 'failed'
        state.error = action.error.message || null
      })
  }
})
export const { setCurrentRoutePermKey } = permissionSlice.actions
export default permissionSlice.reducer
