import { createSlice, PayloadAction, createAsyncThunk } from '@reduxjs/toolkit'
import axios from 'axios'
import { SLICE_CURRENT_SUBSCRIPTION_NAME } from './constants'
import appConfig from '@/configs/app.config'
import { getLocale } from '@/utils/locale'
import { getAccessToken } from '@/utils/accessToken'

export type currentSubState = {
  currentRouteKey: string
  apiData: any[]
  status: 'idle' | 'loading' | 'succeeded' | 'failed'
  error: string | null
}

export const initialCurrentSubState: currentSubState = {
  currentRouteKey: '',
  apiData: [],
  status: 'idle',
  error: null
}

// Define an async thunk for fetching API data
export const fetchCurrentSubApiData = createAsyncThunk(
  `${SLICE_CURRENT_SUBSCRIPTION_NAME}/current_sub`,
  async (_, thunkAPI) => {
    const accessToken = getAccessToken(thunkAPI)

    const config = {
      url: 'subscription/current-subscription/',
      method: 'get',
      baseURL: `${appConfig.PaymentsApiPrefix}`,
      headers: {
        'accept-language': getLocale(),
        'content-type': 'application/json',
        Authorization: `Bearer ${accessToken}`
      }
    }

    const response = await axios(config)
    return response.data
  }
)

export const currentSubSlice = createSlice({
  name: `${SLICE_CURRENT_SUBSCRIPTION_NAME}/current_sub`,
  initialState: initialCurrentSubState,
  reducers: {
    setCurrentSubRouteKey: (state, action: PayloadAction<string>) => {
      state.currentRouteKey = action.payload
    }
  },
  extraReducers: builder => {
    builder
      .addCase(fetchCurrentSubApiData.pending, state => {
        state.status = 'loading'
      })
      .addCase(fetchCurrentSubApiData.fulfilled, (state, action) => {
        state.status = 'succeeded'
        state.apiData = action.payload
      })
      .addCase(fetchCurrentSubApiData.rejected, (state, action) => {
        state.status = 'failed'
        state.error = action.error.message || null
      })
  }
})

export const { setCurrentSubRouteKey } = currentSubSlice.actions

export default currentSubSlice.reducer
