import i18n from 'i18next'
import { initReactI18next } from 'react-i18next'
import LanguageDetector from 'i18next-browser-languagedetector'
import en from './lang/en.json'
import ar from './lang/ar.json'
import appConfig from '@/configs/app.config'

const resources = {
  en: {
    translation: en
  },
  ar: {
    translation: ar
  }
}

export const supportedLngs = {
  en: 'English',
  ar: 'Arabic (العربية)'
}

i18n
  .use(initReactI18next)
  .use(LanguageDetector)
  .init({
    resources,
    fallbackLng: appConfig.locale,
    supportedLngs: Object.keys(supportedLngs),
    interpolation: {
      escapeValue: false
    }
  })

export const dateLocales: {
  [key: string]: () => Promise<ILocale>
} = {
  en: () => import('dayjs/locale/en'),
  ar: () => import('dayjs/locale/ar')
}

export default i18n
