import { createSlice, PayloadAction, createAsyncThunk } from '@reduxjs/toolkit'
import axios from 'axios'
import appConfig from '@/configs/app.config'
import { GET_SETTING } from './constants'
import { getLocale } from '@/utils/locale'
import { getAccessToken } from '@/utils/accessToken'

export type GetCalendarSettingState = {
  currentRouteKey: string
  apiData: any[]
  status: 'idle' | 'loading' | 'succeeded' | 'failed'
  error: string | null
}

export const initialGetCalendarSettingState: GetCalendarSettingState = {
  currentRouteKey: '',
  apiData: [],
  status: 'idle',
  error: null
}

// Define an async thunk for fetching API data
export const fetchGetCalendarSettingReportData = createAsyncThunk(
  `${GET_SETTING}/fetchGetCalendarSettingReportData`,
  async (propId: any, thunkAPI) => {
    const accessToken = getAccessToken(thunkAPI)

    const config = {
      url: `calendar/get-calendar-setting/?property=${propId}`,
      method: 'get',
      baseURL: `${appConfig.propertyApiPrefix}`,
      headers: {
        'accept-language': getLocale(),
        'content-type': 'application/json',
        Authorization: `Bearer ${accessToken}`
      }
    }

    const response = await axios(config)
    return response.data
  }
)

export const GetCalendarSettingSlice = createSlice({
  name: `${GET_SETTING}/getSetting`,
  initialState: initialGetCalendarSettingState,
  reducers: {
    setCurrentGetCalendarSettingRouteKey: (
      state,
      action: PayloadAction<string>
    ) => {
      state.currentRouteKey = action.payload
    }
  },
  extraReducers: builder => {
    builder
      .addCase(fetchGetCalendarSettingReportData.pending, state => {
        state.status = 'loading'
      })
      .addCase(fetchGetCalendarSettingReportData.fulfilled, (state, action) => {
        state.status = 'succeeded'
        state.apiData = action.payload
      })
      .addCase(fetchGetCalendarSettingReportData.rejected, (state, action) => {
        state.status = 'failed'
        state.error = action.error.message || null
      })
  }
})

export const { setCurrentGetCalendarSettingRouteKey } =
  GetCalendarSettingSlice.actions

export default GetCalendarSettingSlice.reducer
