export const SLICE_USERS_NAME = 'users'
export const SLICE_PROPERTY_BASE_NAME = 'property'
export const SLICE_PROPERTY_ROOM_BASE_NAME = 'propertyRoom'
export const SLICE_PROPERTY_TYPE_BASE_NAME = 'propertyType'
export const SLICE_SELECTED_PROPERTY_BASE_NAME = 'selectedProperty'
export const SLICE_PROPERTY_ROOM_NUMBER_BASE_NAME = 'propertyRoomNumber'
export const SLICE_PROPERTY_USER = 'propertyUser'
export const SLICE_UNIT_TYPE_BASE_NAME = 'unitProperty'
export const SLICE_AMENITIES_NAME = 'amenities'
export const GET_ROPERTY_POLICY_TYPE_BASE_NAME = 'getPropertyPolicy'
export const GET_EXTRAS_BASE_NAME = 'extras'
export const SLICE_CATEGORY_BASE_NAME = 'categories'
export const GET_RESTRICTION_PLAN_BASE_NAME = 'getRestrictionPlan'
export const GET_PRICING_PLAN_BASE_NAME = 'getPricingPlan'
export const GET_CANCELLATION_POLICY_BASE_NAME = 'getCancellationPolicy'
export const GET_CANCELLATION_POLICY_TYPE_BASE_NAME =
  'getCancellationPolicyType'
export const SLICE_PROPERTY_ROOM_NUMBER_FLOOR_BASE_NAME =
  'PropertyRoomNumberFloor'
export const SLICE_PROPERTY_FLOOR_BASE_NAME = 'propertyFloor'
export const GET_PROMOTION_DATA_BASE_NAME = 'getPromotionType'
export const GET_SPECIAL_DATA_BASE_NAME = 'getSpecialData'
export const GET_UNIT_DATA_BASE_NAME = 'getUnitData'
export const GET_VARIATION_DATA_BASE_NAME = 'getVariationData'
export const GET_ROOMS_FOR_PROPERTY = 'getRoomsForProperty'
export const GET_ROOM_NUMBERS_FOR_UNIT = 'getRoomsForProperty'
export const GET_PROMOTION_DATA_TYPE_BASE_NAME = 'getPromotionTypeName'
export const GET_ALL_DIGITAL_KEYS = 'getAllDigitalKeys'
export const GET_ROOMS_NUMBERS = 'getRoomNumbers'
export const GET_ALL_ROOMS_DATA_BASE_NAME = 'getAllRooms'
export const GET_ROOM_NFC_KEY = 'getRoomNfcKey'
export const GET_LOST_AND_FOUND = 'getLostAndFound'
export const GET_MAINTENANCE = 'getMaintenance'
export const GET_NOTIFICATION = 'getNotification'
export const GET_ALL_NOTIFICATION = 'getAllNotification'
export const GET_SETTING = 'getSetting'
export const GET_ROOM_OUT_OF_SERVICE = 'getRoomOutOfService'
export const GET_YEILDC_CALENDAR_VARIATIONS = 'getYeildCalendarVariations'
export const GET_RANDOM_PASSCODE = 'getRandomPascode'
