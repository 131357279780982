import { createSlice, PayloadAction, createAsyncThunk } from '@reduxjs/toolkit'
import axios from 'axios'
import { GET_ROPERTY_POLICY_TYPE_BASE_NAME } from './constants'
import appConfig from '@/configs/app.config'
import { getLocale } from '@/utils/locale'
import { getAccessToken } from '@/utils/accessToken'

export type PropertyPolicyState = {
  currentRouteKey: string
  apiData: any[]
  status: 'idle' | 'loading' | 'succeeded' | 'failed'
  error: string | null
}

export const initialPropertyPolicyState: PropertyPolicyState = {
  currentRouteKey: '',
  apiData: [],
  status: 'idle',
  error: null
}

// Define an async thunk for fetching API data
export const fetchPropertyPolicyApiData = createAsyncThunk(
  `${GET_ROPERTY_POLICY_TYPE_BASE_NAME}/fetchPropertyPolicyApiData`,
  async (id: number | string, thunkAPI) => {
    const accessToken = getAccessToken(thunkAPI)

    const config = {
      url: `policy/get-property-policy/?property_id=${id}`,
      method: 'get',
      baseURL: `${appConfig.propertyApiPrefix}`,
      headers: {
        'accept-language': getLocale(),
        'content-type': 'application/json',
        Authorization: `Bearer ${accessToken}`
      }
    }

    const response = await axios(config)
    return response.data
  }
)

export const propertyPolicySlice = createSlice({
  name: `${GET_ROPERTY_POLICY_TYPE_BASE_NAME}/users`,
  initialState: initialPropertyPolicyState,
  reducers: {
    setCurrentPropertPloicyRouteKey: (state, action: PayloadAction<string>) => {
      state.currentRouteKey = action.payload
    }
  },
  extraReducers: builder => {
    builder
      .addCase(fetchPropertyPolicyApiData.pending, state => {
        state.status = 'loading'
      })
      .addCase(fetchPropertyPolicyApiData.fulfilled, (state, action) => {
        state.status = 'succeeded'
        state.apiData = action.payload
      })
      .addCase(fetchPropertyPolicyApiData.rejected, (state, action) => {
        state.status = 'failed'
        state.error = action.error.message || null
      })
  }
})

export const { setCurrentPropertPloicyRouteKey } = propertyPolicySlice.actions

export default propertyPolicySlice.reducer
