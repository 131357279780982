import { createSlice } from '@reduxjs/toolkit'
import { SLICE_BASE_NAME } from './constants'

export type UserState = {
  user: any
  authority: any
}
const initialState: UserState = {
  user: null,
  authority: []
}

const userSlice = createSlice({
  name: `${SLICE_BASE_NAME}/user`,
  initialState,
  reducers: {
    setUser(state, action) {
      state.user = action.payload
    }
  }
})
export const { setUser } = userSlice.actions
export default userSlice.reducer
