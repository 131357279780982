import { createSlice, PayloadAction, createAsyncThunk } from '@reduxjs/toolkit'
import axios from 'axios'
import { GET_ROOMS_FOR_PROPERTY } from './constants'
import appConfig from '@/configs/app.config'
import { getLocale } from '@/utils/locale'
import { getAccessToken } from '@/utils/accessToken'

export type PropertyRoomForPropertyState = {
  currentRouteKey: string
  apiData: any[]
  status: 'idle' | 'loading' | 'succeeded' | 'failed'
  error: string | null
}

export const initialRoomForPropertyState: PropertyRoomForPropertyState = {
  currentRouteKey: '',
  apiData: [],
  status: 'idle',
  error: null
}

// Define an async thunk for fetching API data
export const fetchRoomForPropertyApiData = createAsyncThunk(
  `${GET_ROOMS_FOR_PROPERTY}/RoomForProperty`,
  async (commaArray: any, thunkAPI) => {
    const accessToken = getAccessToken(thunkAPI)

    const config = {
      url: `room/get-rooms-for-property/?property_id=${commaArray}`,
      method: 'get',
      baseURL: `${appConfig.propertyApiPrefix}`,
      headers: {
        'accept-language': getLocale(),
        'content-type': 'application/json',
        Authorization: `Bearer ${accessToken}`
      }
    }

    const response = await axios(config)
    return response.data
  }
)

export const RoomForPropertySlice = createSlice({
  name: `${GET_ROOMS_FOR_PROPERTY}/RoomForProperty`,
  initialState: initialRoomForPropertyState,
  reducers: {
    setCurrentRoomForPropertRoomRouteKey: (
      state,
      action: PayloadAction<string>
    ) => {
      state.currentRouteKey = action.payload
    }
  },
  extraReducers: builder => {
    builder
      .addCase(fetchRoomForPropertyApiData.pending, state => {
        state.status = 'loading'
      })
      .addCase(fetchRoomForPropertyApiData.fulfilled, (state, action) => {
        state.status = 'succeeded'
        state.apiData = action.payload
      })
      .addCase(fetchRoomForPropertyApiData.rejected, (state, action) => {
        state.status = 'failed'
        state.error = action.error.message || null
      })
  }
})

export const { setCurrentRoomForPropertRoomRouteKey } =
  RoomForPropertySlice.actions

export default RoomForPropertySlice.reducer
