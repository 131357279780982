import { createSlice, PayloadAction, createAsyncThunk } from '@reduxjs/toolkit'
import axios from 'axios'
import { SLICE_BOOKED_ROOMS } from './constant'
import appConfig from '@/configs/app.config'
import { getLocale } from '@/utils/locale'
import { getAccessToken } from '@/utils/accessToken'

export type GetBookedRoomsState = {
  currentRouteKey: string
  apiData: any[]
  status: 'idle' | 'loading' | 'succeeded' | 'failed'
  error: string | null
}

export const initialBookedRoomsState: GetBookedRoomsState = {
  currentRouteKey: '',
  apiData: [],
  status: 'idle',
  error: null
}

// Define an async thunk for fetching API data
export const fetchGetBookedRoomsApiData = createAsyncThunk(
  `${SLICE_BOOKED_ROOMS}/fetchGetBookedRoomsApiData`,
  async (id: number, thunkAPI) => {
    const accessToken = getAccessToken(thunkAPI)

    const config = {
      url: `reservation/get-rooms/?reservation_id=${id}`,
      method: 'get',
      baseURL: `${appConfig.reservationApiPrefix}`,
      headers: {
        'accept-language': getLocale(),
        'content-type': 'application/json',
        Authorization: `Bearer ${accessToken}`
      }
    }

    const response = await axios(config)
    return response.data
  }
)

export const getBookedRoomsSlice = createSlice({
  name: `${SLICE_BOOKED_ROOMS}/getBookeRooms`,
  initialState: initialBookedRoomsState,
  reducers: {
    setCurrentGetBookedRoomsRouteKey: (
      state,
      action: PayloadAction<string>
    ) => {
      state.currentRouteKey = action.payload
    }
  },
  extraReducers: builder => {
    builder
      .addCase(fetchGetBookedRoomsApiData.pending, state => {
        state.status = 'loading'
      })
      .addCase(fetchGetBookedRoomsApiData.fulfilled, (state, action) => {
        state.status = 'succeeded'
        state.apiData = action.payload
      })
      .addCase(fetchGetBookedRoomsApiData.rejected, (state, action) => {
        state.status = 'failed'
        state.error = action.error.message || null
      })
  }
})

export const { setCurrentGetBookedRoomsRouteKey } = getBookedRoomsSlice.actions

export default getBookedRoomsSlice.reducer
