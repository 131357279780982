import { createSlice, PayloadAction, createAsyncThunk } from '@reduxjs/toolkit'
import axios from 'axios'
import { AxiosError } from 'axios'

import { GET_RANDOM_PASSCODE } from '../constants'
import appConfig from '@/configs/app.config'
import { getLocale } from '@/utils/locale'
import { getAccessToken } from '@/utils/accessToken'

export type RandomPasscodeState = {
  currentRouteKey: string
  apiData: any[]
  status: 'idle' | 'loading' | 'succeeded' | 'failed'
  error: string | null
}

export const initialRandomPasscodeState: RandomPasscodeState = {
  currentRouteKey: '',
  apiData: [],
  status: 'idle',
  error: null
}

// Define an async thunk for fetching API data
export const fetchRandomPasscode = createAsyncThunk(
  `${GET_RANDOM_PASSCODE}/getAllDigitalKeys`,
  async (
    {
      lock_id,
      start_date,
      end_date
    }: {
      lock_id: number
      start_date: any
      end_date: any
    },
    thunkAPI
  ) => {
    const accessToken = getAccessToken(thunkAPI)

    const { rejectWithValue } = thunkAPI

    const config = {
      // url: `room/get-all-room-digital-key/?property_id=${property_id}`,
      url: `room/get-passcode/?lock_id=${lock_id}&start_date=${start_date}&end_date=${end_date}`,
      method: 'get',
      baseURL: `${appConfig.propertyApiPrefix}`,
      headers: {
        'accept-language': getLocale(),
        'content-type': 'application/json',
        Authorization: `Bearer ${accessToken}`
      }
    }

    try {
      const response = await axios(config)
      return response.data
    } catch (error: any) {
      return rejectWithValue(
        error.response ? error.response.data : error.message
      )
    }
  }
)

export const GetRandomPasscodeSlice = createSlice({
  name: `${GET_RANDOM_PASSCODE}/category`,
  initialState: initialRandomPasscodeState,
  reducers: {
    setCurrentRandomPasscodeRouteKey: (
      state,
      action: PayloadAction<string>
    ) => {
      state.currentRouteKey = action.payload
    }
  },
  extraReducers: builder => {
    builder
      .addCase(fetchRandomPasscode.pending, state => {
        state.status = 'loading'
      })
      .addCase(fetchRandomPasscode.fulfilled, (state, action) => {
        state.status = 'succeeded'
        state.apiData = action.payload
      })
      .addCase(fetchRandomPasscode.rejected, (state, action) => {
        state.status = 'failed'
        state.error = action.error.message || null
      })
  }
})

export const { setCurrentRandomPasscodeRouteKey } =
  GetRandomPasscodeSlice.actions

export default GetRandomPasscodeSlice.reducer
