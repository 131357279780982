import { createSlice, PayloadAction, createAsyncThunk } from '@reduxjs/toolkit'
import axios from 'axios'
import { GET_ALL_DIGITAL_KEYS } from '../constants'
import appConfig from '@/configs/app.config'
import { getLocale } from '@/utils/locale'
import { getAccessToken } from '@/utils/accessToken'

export type DigitalKeysState = {
  currentRouteKey: string
  apiData: any[]
  status: 'idle' | 'loading' | 'succeeded' | 'failed'
  error: string | null
}

export const initialDigitalKeysState: DigitalKeysState = {
  currentRouteKey: '',
  apiData: [],
  status: 'idle',
  error: null
}

// Define an async thunk for fetching API data
export const fetchAllDigitalKeysApiData = createAsyncThunk(
  `${GET_ALL_DIGITAL_KEYS}/getAllDigitalKeys`,
  async (property_id: any, thunkAPI) => {
    const accessToken = getAccessToken(thunkAPI)

    const config = {
      // url: `room/get-all-room-digital-key/?property_id=${property_id}`,
      url: `room/list-lock-data/?property=${property_id}`,
      method: 'get',
      baseURL: `${appConfig.propertyApiPrefix}`,
      headers: {
        'accept-language': getLocale(),
        'content-type': 'application/json',
        Authorization: `Bearer ${accessToken}`
      }
    }

    const response = await axios(config)
    return response.data
  }
)

export const GetAllDigitalKeysSlice = createSlice({
  name: `${GET_ALL_DIGITAL_KEYS}/category`,
  initialState: initialDigitalKeysState,
  reducers: {
    setCurrentDigitalRouteKey: (state, action: PayloadAction<string>) => {
      state.currentRouteKey = action.payload
    }
  },
  extraReducers: builder => {
    builder
      .addCase(fetchAllDigitalKeysApiData.pending, state => {
        state.status = 'loading'
      })
      .addCase(fetchAllDigitalKeysApiData.fulfilled, (state, action) => {
        state.status = 'succeeded'
        state.apiData = action.payload
      })
      .addCase(fetchAllDigitalKeysApiData.rejected, (state, action) => {
        state.status = 'failed'
        state.error = action.error.message || null
      })
  }
})

export const { setCurrentDigitalRouteKey } = GetAllDigitalKeysSlice.actions

export default GetAllDigitalKeysSlice.reducer
