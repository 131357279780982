/*
Author: Aftab Rehan (aftab.rehan@syncclouds.com)
Created: 19/08/2024, 14:35:52
Modified: 19/08/2024, 17:29:45

Description:
  This file contains the `Theme` component, which configures the application's theme settings and provides them to child components via the `ConfigProvider`.

  - Functionality:
    - Theme Integration: Combines the application's theme configuration (`themeConfig`) with the current theme state from the Redux store, along with the current locale.
    - Dark Mode: Automatically applies dark mode settings by invoking the `useDarkMode` hook.
    - Locale Support: Integrates the current locale into the theme configuration using the `useLocale` hook.
    - Configuration Provider: Wraps child components with the `ConfigProvider`, passing down the combined theme settings.

  - Hooks Used:
    - `useDarkMode`: Manages dark mode settings.
    - `useLocale`: Retrieves the current locale.
    - `useAppSelector`: Selects the current theme from the Redux store.

  The `Theme` component centralizes and manages the application's theme settings, ensuring a consistent appearance and localization across the entire application.
*/

import { useAppSelector } from '@/store'

import ConfigProvider from '@/components/ui/ConfigProvider'

import useDarkMode from '@/utils/hooks/useDarkmode'
import useLocale from '@/utils/hooks/useLocale'

import { themeConfig } from '@/configs/theme.config'

import type { CommonProps } from '@/@types/common'

const Theme = (props: CommonProps) => {
  useDarkMode()

  const theme = useAppSelector(state => state.theme)
  const { locale, direction } = useLocale()

  const currentTheme = {
    ...themeConfig,
    ...theme,
    locale,
    direction
  }

  return <ConfigProvider value={currentTheme}>{props.children}</ConfigProvider>
}

export default Theme
