import { createSlice } from '@reduxjs/toolkit'
import { SLICE_SELECTED_PROPERTY_BASE_NAME } from './constants'

export type selectedPropertyState = {
  property: any
}

const initialState: selectedPropertyState = {
  property: null
}

const selectedPropertySlice = createSlice({
  name: `${SLICE_SELECTED_PROPERTY_BASE_NAME}/selectedProperty`,
  initialState,
  reducers: {
    setSelectedProperty(state, action) {
      state.property = action.payload
    }
  }
})

export const { setSelectedProperty } = selectedPropertySlice.actions
export default selectedPropertySlice.reducer
