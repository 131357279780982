export const SLICE_GETRESERVATION_TYPE = 'getReservationType'
export const SLICE_GETMEAL_TYPE = 'getMealType'
// export const GET_BOARDS_DATA_BASE_NAME = 'getBoards'
export const SLICE_SALES_CHANNEL_TYPE = 'getSalesChannel'
export const SLICE_RESERVATION_DATA = 'getReservationData'
export const SLICE_GUEST_STATUS = 'getGuestStatus'
export const SLICE_DISCOUNT_TYPE = 'getdiscountType'
export const SLICE_GETDOCUMENT_TYPE = 'getDocType'
export const SLICE_GETCOMPANY = 'getcompany'
export const SLICE_GETPEOPLE = 'getPeople'
export const SLICE_BOOKED_ROOMS = 'getBookeRooms'
export const SLICE_EXTRAS_DATA = 'getExtrasData'
export const GET_BOARDS_DATA_BASE_NAME = 'getBoardsType'
export const GET_CITY_TAX_USAGE = 'getCityTaxUsage'
export const GET_CITY_TAX = 'getCityTax'
export const GET_BOARDS_MEAL = 'getBoardsMeals'
export const GET_UNIT_DATA = 'getUnitData'
export const GET_INVOICE_TYPE = 'getInvoicesType'
export const SLICE_GET_CUSTOM_TAX = 'getCustomTax'
export const SLICE_GET_NONE_BOOKED_ROOMS = 'getNoneBookedRooms'
export const SLICE_GET_INVOICES = 'getInvoices'
export const GET_SINGLE_INVOICE = 'getSingleInvoice'

export const GET_BOOKINGENGINE = 'getBookingEngine'

export const GET_PROMOCODE = 'getPromocode'
export const GET_BOOKING_ENGINE_RES = 'getBookingEngineRes'
export const GET_BOOKING_ENGINE_PROPERTIES = 'getBookingEngineProperties'

export const GET_BOOKING_ENGINE_GUEST = 'getBookingEngineGuest'
export const BOOKING_ENGINE_GUEST = 'bookingEngineGuest'
export const PROMOCODE_BOOKING_ENGINE = 'promocodeBookingEngine'
export const GETINVOCE_FOR_RESERVATION = 'getInvoiceForReservation'
export const HOURSEKEEPING_REPORT = 'getHousekeepingReport'
export const MONTHLY_REVENUE_REPORT = 'getmonthlyReport'
export const COUNTRY_REVENUE_REPORT = 'getCountryReport'
export const COMPANY_REVENUE_REPORT = 'getCompanyReport'
export const PACE_REPORTS = 'getPaceRepports'
