import { createSlice, PayloadAction, createAsyncThunk } from '@reduxjs/toolkit'
import axios from 'axios'
import { GET_CITY_TAX_USAGE } from './constant'
import appConfig from '@/configs/app.config'
import { getLocale } from '@/utils/locale'
import { getAccessToken } from '@/utils/accessToken'
export type GetCityTaxUsageState = {
  currentRouteKey: string
  apiData: any[]
  status: 'idle' | 'loading' | 'succeeded' | 'failed'
  error: string | null
}
export const initialCItyTaxUsageState: GetCityTaxUsageState = {
  currentRouteKey: '',
  apiData: [],
  status: 'idle',
  error: null
}
// Define an async thunk for fetching API data
export const fetchGetCityTaxUsageApiData = createAsyncThunk(
  `${GET_CITY_TAX_USAGE}/fetchGetCityTaxUsageApiData`,
  async (prop_Id: any, thunkAPI) => {
    const accessToken = getAccessToken(thunkAPI)

    const config = {
      url: `boards/get-city-tax-usage/?property_id=${prop_Id}`,
      method: 'get',
      baseURL: `${appConfig.reservationApiPrefix}`,
      headers: {
        'accept-language': getLocale(),
        'content-type': 'application/json',
        Authorization: `Bearer ${accessToken}`
      }
    }

    const response = await axios(config)
    return response.data
  }
)

export const GetCityTaxUsageSlice = createSlice({
  name: `${GET_CITY_TAX_USAGE}/getCityTaxUsage`,
  initialState: initialCItyTaxUsageState,
  reducers: {
    setCurrentGetCityTaxUsageRouteKey: (
      state,
      action: PayloadAction<string>
    ) => {
      state.currentRouteKey = action.payload
    }
  },
  extraReducers: builder => {
    builder
      .addCase(fetchGetCityTaxUsageApiData.pending, state => {
        state.status = 'loading'
      })
      .addCase(fetchGetCityTaxUsageApiData.fulfilled, (state, action) => {
        state.status = 'succeeded'
        state.apiData = action.payload
      })
      .addCase(fetchGetCityTaxUsageApiData.rejected, (state, action) => {
        state.status = 'failed'
        state.error = action.error.message || null
      })
  }
})

export const { setCurrentGetCityTaxUsageRouteKey } =
  GetCityTaxUsageSlice.actions

export default GetCityTaxUsageSlice.reducer
