import { createSlice, PayloadAction, createAsyncThunk } from '@reduxjs/toolkit'
import axios from 'axios'
import appConfig from '@/configs/app.config'
import { GET_ROOM_OUT_OF_SERVICE } from './constants'
import { getLocale } from '@/utils/locale'
import { getAccessToken } from '@/utils/accessToken'

export type GetRoomOutOfServiceState = {
  currentRouteKey: string
  apiData: any[]
  status: 'idle' | 'loading' | 'succeeded' | 'failed'
  error: string | null
}

export const initialGetRoomOutOfServiceState: GetRoomOutOfServiceState = {
  currentRouteKey: '',
  apiData: [],
  status: 'idle',
  error: null
}

// Define an async thunk for fetching API data
export const fetchGetRoomOutOfServiceReportData = createAsyncThunk(
  `${GET_ROOM_OUT_OF_SERVICE}/fetchGetRoomOutOfServiceReportData`,
  async (roomId: any, thunkAPI) => {
    const accessToken = getAccessToken(thunkAPI)

    const config = {
      url: `calendar/get-out-of-service/?room=${roomId}`,
      method: 'get',
      baseURL: `${appConfig.propertyApiPrefix}`,
      headers: {
        'accept-language': getLocale(),
        'content-type': 'application/json',
        Authorization: `Bearer ${accessToken}`
      }
    }

    const response = await axios(config)
    return response.data
  }
)

export const GetRoomOutOfServiceSlice = createSlice({
  name: `${GET_ROOM_OUT_OF_SERVICE}/getRoomOutOfService`,
  initialState: initialGetRoomOutOfServiceState,
  reducers: {
    setCurrentGetRoomOutOfServiceRouteKey: (
      state,
      action: PayloadAction<string>
    ) => {
      state.currentRouteKey = action.payload
    }
  },
  extraReducers: builder => {
    builder
      .addCase(fetchGetRoomOutOfServiceReportData.pending, state => {
        state.status = 'loading'
      })
      .addCase(
        fetchGetRoomOutOfServiceReportData.fulfilled,
        (state, action) => {
          state.status = 'succeeded'
          state.apiData = action.payload
        }
      )
      .addCase(fetchGetRoomOutOfServiceReportData.rejected, (state, action) => {
        state.status = 'failed'
        state.error = action.error.message || null
      })
  }
})

export const { setCurrentGetRoomOutOfServiceRouteKey } =
  GetRoomOutOfServiceSlice.actions

export default GetRoomOutOfServiceSlice.reducer
