import { combineReducers } from '@reduxjs/toolkit'
import getCreditCard, { creditCardState } from './GetCreditCardsSlice'
import getPayments, { paymentState } from './GetPaymentSlice'
import getCurrentSub, { currentSubState } from './GetCurrentSubSlice'
import getProductSub, { prodcutSubState } from './GetProductSubSlice'
import getInvoiceSub, { invoiceSubState } from './GetInvoiceSub'
const reducer = combineReducers({
  getCreditCard,
  getPayments,
  getCurrentSub,
  getProductSub,
  getInvoiceSub
})
export type PaymentManageState = {
  getPayments: paymentState
  getCreditCard: creditCardState
  getCurrentSub: currentSubState
  getProductSub: prodcutSubState
  getInvoiceSub: invoiceSubState
}

export * from './GetCreditCardsSlice'
export * from './GetPaymentSlice'
export * from './GetCurrentSubSlice'
export * from './GetProductSubSlice'
export * from './GetInvoiceSub'
export default reducer
