import appConfig from '@/configs/app.config'
import ApiService from './ApiService'
import type {
  SignInCredential,
  SignUpCredential,
  ForgotPassword,
  ResetPassword,
  addPhone,
  verifyOtp,
  resendOtp,
  SignOut,
  addRole,
  editRole,
  changePasswordType,
  RolePermission,
  EditMember,
  DeleteMember
} from '@/@types/auth'
export async function apiSignIn(data: SignInCredential) {
  return ApiService.fetchData({
    url: 'auth/login/',
    method: 'post',
    data,
    token: null
  })
}
export async function apiSignUp(data: SignUpCredential) {
  return ApiService.fetchData({
    url: 'auth/signup/',
    method: 'post',
    data,
    token: null
  })
}

export const addPhoneNumber = (data: addPhone) => {
  const id = localStorage.getItem('userId')
  let userId = null
  if (typeof id === 'string') {
    userId = JSON.parse(id)
  }
  return ApiService.fetchData({
    url: `auth/add-phone-number/${userId}/`,
    method: 'patch',
    data,
    token: null
  })
}

export const verifyOtpApiCall = (data: verifyOtp) => {
  return ApiService.fetchData({
    url: 'auth/verify_otp/',
    method: 'post',
    data,
    token: null
  })
}

export async function resendOtpApiCall(data: resendOtp) {
  return ApiService.fetchData({
    url: 'auth/resend_otp/',
    method: 'post',
    data,
    token: null
  })
}
export async function apiSignOut(data: SignOut) {
  return ApiService.fetchData({
    url: 'auth/logout/',
    method: 'post',
    data,
    token: data?.token
  })
}
export async function apiForgotPassword(data: ForgotPassword) {
  return ApiService.fetchData({
    url: 'auth/forget-password/',
    method: 'post',
    data,
    token: null
  })
}
export async function apiResetPassword(data: ResetPassword) {
  return ApiService.fetchData({
    url: `${appConfig.authApiPrefix}auth/reset-password-confirm/?uid=${data?.uid?.uid}&token=${data?.uid?.token}`,
    method: 'patch',
    data: {
      password: data.password,
      new_password: data.new_password,
      uid: data?.uid?.uid,
      token: data?.uid?.token
    },
    token: null
  })
}
// apiChangePassword
export async function apiChangePassword(data: changePasswordType) {
  const formdata = new FormData()
  formdata.append('old_password', data?.password)
  formdata.append('password', data?.newPassword)
  formdata.append('confirm_password', data?.confirmNewPassword)
  return ApiService.fetchData({
    url: 'auth/change-password/',
    method: 'put',
    data: formdata,
    token: data?.token
  })
}
export async function apiAddRole(data: addRole) {
  const formData = new FormData()
  formData.append('role_type_code', data?.role_type_name)
  formData.append('role_type_name', data.role_type_name)
  formData.append('property_id', data?.property_id)

  return ApiService.fetchData({
    url: 'auth/add-role/',
    method: 'post',
    data: formData,
    token: data?.token
  })
}
// =============================edit role=================================
export async function apiEditRole(data: editRole) {
  const formData = new FormData()
  formData.append('role_type_name', data.role_type_name)
  formData.append('property_id', data?.property_id)
  return ApiService.fetchData({
    url: `auth/update-role/${data?.roleId}/`,
    method: 'put',
    data: formData,
    token: data?.token
  })
}
// =============================edit member=================================
export async function apiEditMember(data: EditMember) {
  const formData = new FormData()
  formData.append('user_role', data.role_id)
  formData.append('property_id', data.selectedProperty)
  return ApiService.fetchData({
    url: `auth/add-member/${data?.id}/`,
    method: 'put',
    data: formData,
    token: data?.token
  })
}
// =============================del member=================================
export async function apiDeleteMember(data: DeleteMember) {
  console.log('delete member data', data)
  const formData = new FormData()
  formData.append('role_id', data.role_id)
  return ApiService.fetchData({
    url: `auth/remove-member/${data?.id}/`,
    method: 'delete',
    data: formData,
    token: data?.token
  })
}
// =============================edit role permission=================================
export async function apiEditRolePermission(data: RolePermission) {
  const permissions = data?.permission?.map((per: any) => ({
    id: per?.id,
    permission_type: per?.permission
  }))

  const raw = JSON.stringify({
    permission: permissions
  })
  return ApiService.fetchData({
    url: 'auth/update-role-permission/',
    method: 'put',
    data: raw,
    token: data?.token,
    content_type: true
  })
}

// =============================send invoice to Email=================================
export async function sendEmailInvoice(data: any) {
  console.log('send email data', data)
  const formData = new FormData()
  formData.append('email', data?.email)
  formData.append('file', data?.file, 'generatedDocument.pdf')
  return ApiService.fetchData({
    url: 'account/send-invoice/',
    method: 'post',
    data: formData,
    token: data?.token,
    content_type: false
  })
}
//====================contact us====================
export const apiContactUs = (data: any) => {
  console.log('formData', data)
  const formData = new FormData()
  formData.append('email', data?.email)
  formData.append('full_name', data?.full_name)
  formData.append('subject', data?.subject)
  formData.append('phone_number', data?.phone_number)
  formData.append('country', data?.country)
  formData.append('number_of_units', data?.number_of_units)
  formData.append('property_name', data?.property_name)
  formData.append('description', data?.description)
  return ApiService.fetchData({
    url: 'account/contact-us/',
    method: 'POST',
    data: formData,
    content_type: true
  })
}
