import { createSlice, PayloadAction, createAsyncThunk } from '@reduxjs/toolkit'
import axios from 'axios'
import { GET_SPECIAL_DATA_BASE_NAME } from './constants'
import appConfig from '@/configs/app.config'
import { getLocale } from '@/utils/locale'
import { getAccessToken } from '@/utils/accessToken'

export type SpecialDataState = {
  currentRouteKey: string
  apiData: any[]
  status: 'idle' | 'loading' | 'succeeded' | 'failed'
  error: string | null
}

export const initialSpecialDataState: SpecialDataState = {
  currentRouteKey: '',
  apiData: [],
  status: 'idle',
  error: null
}

// Define an async thunk for fetching API data
export const fetchSpecialDataApiData = createAsyncThunk(
  `${GET_SPECIAL_DATA_BASE_NAME}/fetchSpecialDataApiData`,
  async (id: any, thunkAPI) => {
    const accessToken = getAccessToken(thunkAPI)

    const config = {
      url: `room/get-special-offer/?property_id=${id}`,
      method: 'get',
      baseURL: `${appConfig.propertyApiPrefix}`,
      headers: {
        'accept-language': getLocale(),
        'content-type': 'application/json',
        Authorization: `Bearer ${accessToken}`
      }
    }

    const response = await axios(config)
    return response.data
  }
)

export const getSpecialDataSlice = createSlice({
  name: `${GET_SPECIAL_DATA_BASE_NAME}/getSpecialData`,
  initialState: initialSpecialDataState,
  reducers: {
    setSpecialDataRouteKey: (state, action: PayloadAction<string>) => {
      state.currentRouteKey = action.payload
    }
  },
  extraReducers: builder => {
    builder
      .addCase(fetchSpecialDataApiData.pending, state => {
        state.status = 'loading'
      })
      .addCase(fetchSpecialDataApiData.fulfilled, (state, action) => {
        state.status = 'succeeded'
        state.apiData = action.payload
      })
      .addCase(fetchSpecialDataApiData.rejected, (state, action) => {
        state.status = 'failed'
        state.error = action.error.message || null
      })
  }
})

export const { setSpecialDataRouteKey } = getSpecialDataSlice.actions

export default getSpecialDataSlice.reducer
