export const SLICE_BASE_NAME = 'auth'
export const SLICE_ROLE_NAME = 'role'
export const SLICE_PERM_NAME = 'perms'
export const SLICE_SEC_PERM_NAME = 'section'
export const SLICE_MEMBER_NAME = 'member'
export const SLICE_LANGUAGE_NAME = 'LANGUAGE'
export const SLICE_DASHBOARD_NAME = 'dashboard'
export const SLICE_DEVICE_NAME = 'devices'
export const SLICE_MODULES_NAME = 'getModules'
// export const SLICE_SEC_PERM_NAME = 'section'
