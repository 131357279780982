import { createSlice, PayloadAction, createAsyncThunk } from '@reduxjs/toolkit'
import axios from 'axios'
import { SLICE_CREDIT_CARD_NAME } from './constants'
import appConfig from '@/configs/app.config'
import { getLocale } from '@/utils/locale'
import { getAccessToken } from '@/utils/accessToken'

export type creditCardState = {
  currentRouteKey: string
  apiData: any[]
  status: 'idle' | 'loading' | 'succeeded' | 'failed'
  error: string | null
}

export const initialCreditCardState: creditCardState = {
  currentRouteKey: '',
  apiData: [],
  status: 'idle',
  error: null
}

// Define an async thunk for fetching API data
export const fetchCreditCardApiData = createAsyncThunk(
  `${SLICE_CREDIT_CARD_NAME}/creditCard`,
  async (_, thunkAPI) => {
    const accessToken = getAccessToken(thunkAPI)

    const config = {
      url: 'payment/get-creditcards/',
      method: 'get',
      baseURL: `${appConfig.PaymentsApiPrefix}`,
      headers: {
        'accept-language': getLocale(),
        'content-type': 'application/json',
        Authorization: `Bearer ${accessToken}`
      }
    }

    const response = await axios(config)
    return response.data
  }
)

export const creditCardSlice = createSlice({
  name: `${SLICE_CREDIT_CARD_NAME}/amenities`,
  initialState: initialCreditCardState,
  reducers: {
    setCurrentCreditCardRouteKey: (state, action: PayloadAction<string>) => {
      state.currentRouteKey = action.payload
    }
  },
  extraReducers: builder => {
    builder
      .addCase(fetchCreditCardApiData.pending, state => {
        state.status = 'loading'
      })
      .addCase(fetchCreditCardApiData.fulfilled, (state, action) => {
        state.status = 'succeeded'
        state.apiData = action.payload
      })
      .addCase(fetchCreditCardApiData.rejected, (state, action) => {
        state.status = 'failed'
        state.error = action.error.message || null
      })
  }
})

export const { setCurrentCreditCardRouteKey } = creditCardSlice.actions

export default creditCardSlice.reducer
