/*
Author: Aftab Rehan (aftab.rehan@syncclouds.com)
Created: 12/09/2024, 15:28:08
Modified: 25/09/2024, 11:52:16

Description:
  This file contains the function related to access token.
*/

import { setUser } from '@/store'
import { setSelectedProperty } from '@/store/slices/properties/selectedProperty'

export const getAccessToken = ({ getState, dispatch }: any) => {
  const accessToken = getState()?.auth?.user?.user?.token?.access || ''

  if (accessToken) return accessToken
  else {
    clearUserData(dispatch)
    window.location.reload()

    return null
  }
}

export const clearUserData = (dispatch: any, navigate?: any) => {
  dispatch(setUser(null))
  dispatch(setSelectedProperty(null))
  localStorage.removeItem('selectedProperty')
  localStorage.removeItem('userProperty')
  localStorage.removeItem('userId')
  navigate && navigate('/sign-in')
}
