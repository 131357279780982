import { createSlice, PayloadAction, createAsyncThunk } from '@reduxjs/toolkit'
import axios from 'axios'

import appConfig from '@/configs/app.config'
import { SLICE_PROPERTY_ROOM_BASE_NAME } from './constants'
import { getLocale } from '@/utils/locale'
import { getAccessToken } from '@/utils/accessToken'

export type PropertyRoomState = {
  currentRouteKey: string
  apiData: any[]
  status: 'idle' | 'loading' | 'succeeded' | 'failed'
  error: string | null
}

export const initialPropertyRoomState: PropertyRoomState = {
  currentRouteKey: '',
  apiData: [],
  status: 'idle',
  error: null
}

// Define an async thunk for fetching API data
export const fetchPropertyRoomApiData = createAsyncThunk(
  `${SLICE_PROPERTY_ROOM_BASE_NAME}/fetchPropertyRoomApiData`,
  async (roomState: any, thunkAPI) => {
    if (!roomState) return

    const accessToken = getAccessToken(thunkAPI)

    const config = {
      url: `room/get-property-room/?property_id=${roomState}`,
      method: 'get',
      baseURL: `${appConfig.propertyApiPrefix}`,
      headers: {
        'accept-language': getLocale(),
        'content-type': 'application/json',
        Authorization: `Bearer ${accessToken}`
      }
    }

    const response = await axios(config)
    return response.data
  }
)

export const propertyRoomSlice = createSlice({
  name: `${SLICE_PROPERTY_ROOM_BASE_NAME}/propertyRoom`,
  initialState: initialPropertyRoomState,
  reducers: {
    setCurrentPropertRoomRouteKey: (state, action: PayloadAction<string>) => {
      state.currentRouteKey = action.payload
    }
  },
  extraReducers: builder => {
    builder
      .addCase(fetchPropertyRoomApiData.pending, state => {
        state.status = 'loading'
      })
      .addCase(fetchPropertyRoomApiData.fulfilled, (state, action) => {
        state.status = 'succeeded'
        state.apiData = action.payload
      })
      .addCase(fetchPropertyRoomApiData.rejected, (state, action) => {
        state.status = 'failed'
        state.error = action.error.message || null
      })
  }
})

export const { setCurrentPropertRoomRouteKey } = propertyRoomSlice.actions

export default propertyRoomSlice.reducer
