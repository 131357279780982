import { createSlice, PayloadAction, createAsyncThunk } from '@reduxjs/toolkit'
import axios from 'axios'
import { SLICE_SALES_CHANNEL_TYPE } from './constant'
import appConfig from '@/configs/app.config'
import { getLocale } from '@/utils/locale'
import { getAccessToken } from '@/utils/accessToken'
export type GetSalesChannelState = {
  currentRouteKey: string
  apiData: any[]
  status: 'idle' | 'loading' | 'succeeded' | 'failed'
  error: string | null
}
export const initialGetSalesChannelState: GetSalesChannelState = {
  currentRouteKey: '',
  apiData: [],
  status: 'idle',
  error: null
}
// Define an async thunk for fetching API data
export const fetchGetSalesChannelApiData = createAsyncThunk(
  `${SLICE_SALES_CHANNEL_TYPE}/fetchGetSalesChannelApiData`,
  async (property_id: any, thunkAPI) => {
    const accessToken = getAccessToken(thunkAPI)

    const config = {
      url: `reservation/get-sales-channel/?property_id=${property_id}`,
      method: 'get',
      baseURL: `${appConfig.reservationApiPrefix}`,
      headers: {
        'accept-language': getLocale(),
        'content-type': 'application/json',
        Authorization: `Bearer ${accessToken}`
      }
    }

    const response = await axios(config)
    return response.data
  }
)
export const getSalesChannelSlice = createSlice({
  name: `${SLICE_SALES_CHANNEL_TYPE}/getSalesChannel`,
  initialState: initialGetSalesChannelState,
  reducers: {
    setCurrentGetSalesChannelRouteKey: (
      state,
      action: PayloadAction<string>
    ) => {
      state.currentRouteKey = action.payload
    }
  },
  extraReducers: builder => {
    builder
      .addCase(fetchGetSalesChannelApiData.pending, state => {
        state.status = 'loading'
      })
      .addCase(fetchGetSalesChannelApiData.fulfilled, (state, action) => {
        state.status = 'succeeded'
        state.apiData = action.payload
      })
      .addCase(fetchGetSalesChannelApiData.rejected, (state, action) => {
        state.status = 'failed'
        state.error = action.error.message || null
      })
  }
})
export const { setCurrentGetSalesChannelRouteKey } =
  getSalesChannelSlice.actions
export default getSalesChannelSlice.reducer
