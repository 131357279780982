import { createSlice, PayloadAction, createAsyncThunk } from '@reduxjs/toolkit'
import axios from 'axios'
import { GET_PROMOTION_DATA_BASE_NAME } from './constants'
import appConfig from '@/configs/app.config'
import { getLocale } from '@/utils/locale'
import { getAccessToken } from '@/utils/accessToken'

export type PromotionDataState = {
  currentRouteKey: string
  apiData: any[]
  status: 'idle' | 'loading' | 'succeeded' | 'failed'
  error: string | null
}

export const initialPromotionDataState: PromotionDataState = {
  currentRouteKey: '',
  apiData: [],
  status: 'idle',
  error: null
}

// Define an async thunk for fetching API data
export const fetchPropmotionDataApiData = createAsyncThunk(
  `${GET_PROMOTION_DATA_BASE_NAME}/fetchPropmotionDataApiData`,
  async (id: number, thunkAPI) => {
    const accessToken = getAccessToken(thunkAPI)

    const config = {
      url: `property/get-promotion/?property_id=${id}`,
      method: 'get',
      baseURL: `${appConfig.propertyApiPrefix}`,
      headers: {
        'accept-language': getLocale(),
        'content-type': 'application/json',
        Authorization: `Bearer ${accessToken}`
      }
    }

    const response = await axios(config)
    return response.data
  }
)

export const getPromotionDataSlice = createSlice({
  name: `${GET_PROMOTION_DATA_BASE_NAME}/getPromotionType`,
  initialState: initialPromotionDataState,
  reducers: {
    setPromotionDataRouteKey: (state, action: PayloadAction<string>) => {
      state.currentRouteKey = action.payload
    }
  },
  extraReducers: builder => {
    builder
      .addCase(fetchPropmotionDataApiData.pending, state => {
        state.status = 'loading'
      })
      .addCase(fetchPropmotionDataApiData.fulfilled, (state, action) => {
        state.status = 'succeeded'
        state.apiData = action.payload
      })
      .addCase(fetchPropmotionDataApiData.rejected, (state, action) => {
        state.status = 'failed'
        state.error = action.error.message || null
      })
  }
})

export const { setPromotionDataRouteKey } = getPromotionDataSlice.actions

export default getPromotionDataSlice.reducer
