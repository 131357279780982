import { createSlice, PayloadAction, createAsyncThunk } from '@reduxjs/toolkit'
import axios from 'axios'
import { GET_BOARDS_DATA_BASE_NAME } from './constant'
import appConfig from '@/configs/app.config'
import { getLocale } from '@/utils/locale'
import { getAccessToken } from '@/utils/accessToken'

export type PaceReportsState = {
  currentRouteKey: string
  apiData: any[]
  status: 'idle' | 'loading' | 'succeeded' | 'failed'
  error: string | null
}

export const initialPaceReportsState: PaceReportsState = {
  currentRouteKey: '',
  apiData: [],
  status: 'idle',
  error: null
}
// Define an async thunk for fetching API data
export const fetchGetPaceReportsApiData = createAsyncThunk(
  `${GET_BOARDS_DATA_BASE_NAME}/fetchGetPaceReportsApiData`,
  async ({ id, type }: { id: number; type: string }, thunkAPI) => {
    const accessToken = getAccessToken(thunkAPI)

    const config = {
      url: `reports/pace-report/${type}/?property_id=${id}`,
      method: 'get',
      baseURL: `${appConfig.reservationApiPrefix}`,
      headers: {
        'accept-language': getLocale(),
        'content-type': 'application/json',
        Authorization: `Bearer ${accessToken}`
      }
    }

    const response = await axios(config)
    return response.data
  }
)

const getPaceReportsSlice = createSlice({
  name: `${GET_BOARDS_DATA_BASE_NAME}/getPaceRepports`,
  initialState: initialPaceReportsState,
  reducers: {
    setCurrentGetBoardsRouteKey: (state, action: PayloadAction<string>) => {
      state.currentRouteKey = action.payload
    }
  },
  extraReducers: builder => {
    builder
      .addCase(fetchGetPaceReportsApiData.pending, state => {
        state.status = 'loading'
      })
      .addCase(fetchGetPaceReportsApiData.fulfilled, (state, action) => {
        state.status = 'succeeded'
        state.apiData = action.payload
      })
      .addCase(fetchGetPaceReportsApiData.rejected, (state, action) => {
        state.status = 'failed'
        state.error = action.error.message || null
      })
  }
})

export const { setCurrentGetBoardsRouteKey } = getPaceReportsSlice.actions

export default getPaceReportsSlice.reducer
