/*
Author: Aftab Rehan (aftab.rehan@syncclouds.com)
Created: 19/08/2024, 14:26:01
Modified: 19/08/2024, 17:30:56

Description:
  This file contains the `useLocale` hook, which provides a convenient way to access the current locale/language setting used by the `react-i18next` library. The hook returns the resolved or current language code, which can be used throughout the application for localization purposes.

  Key features include:
  - Utilizes `react-i18next` for accessing the current language settings
  - Returns the resolved language code or the default language code if resolution fails and the direction of the current language
*/

import { useTranslation } from 'react-i18next'

const useLocale = () => {
  const { i18n } = useTranslation()
  const locale = i18n.resolvedLanguage || i18n.language
  const direction = i18n.dir(locale)

  return { locale, direction }
}

export default useLocale
