import { createSlice, PayloadAction, createAsyncThunk } from '@reduxjs/toolkit'
import axios from 'axios'
import { SLICE_CATEGORY_BASE_NAME } from './constants'
import appConfig from '@/configs/app.config'
import { getLocale } from '@/utils/locale'
import { getAccessToken } from '@/utils/accessToken'

export type CategoryState = {
  currentRouteKey: string
  apiData: any[]
  status: 'idle' | 'loading' | 'succeeded' | 'failed'
  error: string | null
}

export const initialCategoriesState: CategoryState = {
  currentRouteKey: '',
  apiData: [],
  status: 'idle',
  error: null
}

// Define an async thunk for fetching API data
export const fetchCategoriesApiData = createAsyncThunk(
  `${SLICE_CATEGORY_BASE_NAME}/category`,
  async (property: any, thunkAPI) => {
    const accessToken = getAccessToken(thunkAPI)

    const config = {
      url: `room/get-category/?property_id=${property}`,
      method: 'get',
      baseURL: `${appConfig.propertyApiPrefix}`,
      headers: {
        'accept-language': getLocale(),
        'content-type': 'application/json',
        Authorization: `Bearer ${accessToken}`
      }
    }

    const response = await axios(config)
    return response.data
  }
)

export const categorySlice = createSlice({
  name: `${SLICE_CATEGORY_BASE_NAME}/category`,
  initialState: initialCategoriesState,
  reducers: {
    setCurrentCategoryRouteKey: (state, action: PayloadAction<string>) => {
      state.currentRouteKey = action.payload
    }
  },
  extraReducers: builder => {
    builder
      .addCase(fetchCategoriesApiData.pending, state => {
        state.status = 'loading'
      })
      .addCase(fetchCategoriesApiData.fulfilled, (state, action) => {
        state.status = 'succeeded'
        state.apiData = action.payload
      })
      .addCase(fetchCategoriesApiData.rejected, (state, action) => {
        state.status = 'failed'
        state.error = action.error.message || null
      })
  }
})

export const { setCurrentCategoryRouteKey } = categorySlice.actions

export default categorySlice.reducer
