import { createSlice, PayloadAction, createAsyncThunk } from '@reduxjs/toolkit'
import axios from 'axios'

import appConfig from '@/configs/app.config'
import { HOURSEKEEPING_REPORT } from '../constant'
import { getLocale } from '@/utils/locale'
import { getAccessToken } from '@/utils/accessToken'

export type houseKeepingState = {
  currentRouteKey: string
  apiData: any[]
  status: 'idle' | 'loading' | 'succeeded' | 'failed'
  error: string | null
}

export const initialHouseKeepingState: houseKeepingState = {
  currentRouteKey: '',
  apiData: [],
  status: 'idle',
  error: null
}

// Define an async thunk for fetching API data
export const fetchHouseKeepingReportData = createAsyncThunk(
  `${HOURSEKEEPING_REPORT}/fetchHouseKeepingReportData`,
  async ({ propId, date }: { propId: any; date?: any }, thunkAPI) => {
    const accessToken = getAccessToken(thunkAPI)

    const url = `reports/get-house-keeping-report/?property_id=${propId}`
    const withDate = `${url}&date=${date}`

    const config = {
      url: date ? withDate : url,
      method: 'get',
      baseURL: `${appConfig.reservationApiPrefix}`,
      headers: {
        'accept-language': getLocale(),
        'content-type': 'application/json',
        Authorization: `Bearer ${accessToken}`
      }
    }

    const response = await axios(config)
    return response.data
  }
)

export const HouseKeepingSlice = createSlice({
  name: `${HOURSEKEEPING_REPORT}/getBoards`,
  initialState: initialHouseKeepingState,
  reducers: {
    setCurrentGetBoardsRouteKey: (state, action: PayloadAction<string>) => {
      state.currentRouteKey = action.payload
    }
  },
  extraReducers: builder => {
    builder
      .addCase(fetchHouseKeepingReportData.pending, state => {
        state.status = 'loading'
      })
      .addCase(fetchHouseKeepingReportData.fulfilled, (state, action) => {
        state.status = 'succeeded'
        state.apiData = action.payload
      })
      .addCase(fetchHouseKeepingReportData.rejected, (state, action) => {
        state.status = 'failed'
        state.error = action.error.message || null
      })
  }
})

export const { setCurrentGetBoardsRouteKey } = HouseKeepingSlice.actions

export default HouseKeepingSlice.reducer
