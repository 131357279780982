/*
Author: Aftab Rehan (aftab.rehan@syncclouds.com)
Created: 20/08/2024, 11:37:42
Modified: 25/09/2024, 11:12:23

Description:
  This file contains the `Layout` component, which is responsible for dynamically loading and rendering different layout components based on the application's current route and authentication state. The `Layout` component ensures that the appropriate layout is used for each page, providing a consistent and contextually relevant user interface.

  Key features of the `Layout` component:
  - Dynamic Layout Selection Utilizes `useMemo` to determine the appropriate layout component to load based on the current route and authentication state. The layout type is selected from a predefined set of layout components:
    - `classicalLayout` for general authenticated views
    - `otherLayout` for various special views and print pages
    - `InvoiceLayout` for invoice-related pages
    - `CancelReservationLayout` for reservation cancellation views
    - `BookingEngineLayoute` for booking engine preview
    - `openKeyDigitalLayout` for digital key-related views (currently commented out)
  - Route-based Layout Switching Checks the current pathname to select and lazy-load the correct layout component, accommodating different routes such as booking previews, invoice prints, and property-related pages.
  - Authentication and Property Authentication Integration Integrates with `useAuth` to determine if the user is authenticated and if property-specific authentication is required. It adjusts the layout based on these authentication states.
  - Suspense for Lazy Loading Uses React's `Suspense` to handle the asynchronous loading of layout components, displaying a `Loading` spinner while the layout component is being loaded.
  - Localization and Document Attributes Employs custom hooks `useLocalizeDocumentAttributes` to ensure document attributes are localized based on the current application context.

  The `Layout` component helps maintain a modular and dynamic approach to rendering layouts, allowing the application to adapt its user interface based on specific routes and user authentication states.
*/

import { useMemo, lazy, Suspense } from 'react'
import { useLocation } from 'react-router-dom'

import Loading from '@/components/shared/Loading'

import { ROUTES } from '@/configs/routes.config/RoutesPaths'
import { useAppSelector } from '@/store'
import useAuth from '@/utils/hooks/useAuth'
import useLocalizeDocumentAttributes from '@/utils/hooks/useLocalizeDocumentAttributes'

const layouts = {
  termAndConditionAndPrivacyPlociLayout: lazy(
    () => import('./TermAndConditionAndPrivacyPolicyLayout')
  ),
  classicalLayout: lazy(() => import('./ClassicLayout')),
  otherLayout: lazy(() => import('./Otherlayout/OtherLayout')),
  InvoiceLayout: lazy(() => import('./InvoiceLayout/InvoiceLayout')),
  CancelReservationLayout: lazy(
    () => import('./CancelReservation/CancelReservationLayout')
  ),
  BookingEngineLayoute: lazy(
    () => import('./BookingEngineLayout/BookingEngineLayout')
  ),
  openKeyDigitalLayout: lazy(
    () => import('./OpenDoorKeyLayout/OpenDoorKeyLayout')
  )
}

const Layout = () => {
  const location = useLocation()
  const { authenticated, propertyAuthenticated } = useAuth()

  useLocalizeDocumentAttributes()

  const layoutType = useAppSelector((state: any) => state.theme.layout.type)

  const AppLayout = useMemo(() => {
    if (
      location?.pathname.split('/')?.[1] === 'term-and-condition' ||
      location?.pathname.split('/')?.[1] === 'privacy-policy'
    ) {
      return layouts.termAndConditionAndPrivacyPlociLayout
    }

    if (location?.pathname.split('/')?.[1] === 'booking-engine-preview')
      return layouts.BookingEngineLayoute
    if (location?.pathname.split('/')?.[1] === 'cancel_reservation')
      return layouts.CancelReservationLayout
    // if (location?.pathname.split('/')?.[1] === 'open-door-with-digital-key')
    //   return layouts.openKeyDigitalLayout
    if (location?.pathname.split('/')?.[1] === 'get-room-key')
      return layouts.otherLayout
    if (location?.pathname.split('/')?.[1] === 'print-report')
      return layouts.otherLayout
    if (location?.pathname === '/page22') return layouts.otherLayout
    if (location?.pathname.split('/')?.[1] === 'print-reservation')
      return layouts.otherLayout
    if (location?.pathname.split('/')?.[1] === 'print-invoice')
      return layouts.InvoiceLayout
    if (location?.pathname.split('/')?.[1] === 'print-billing-invoice')
      return layouts.InvoiceLayout
    if (authenticated) {
      if (propertyAuthenticated && location.pathname !== '/property')
        return layouts.classicalLayout
      if (propertyAuthenticated && location.pathname === '/property')
        return layouts.otherLayout
      if (!propertyAuthenticated) return layouts.otherLayout
    }

    return lazy(() => import('./AuthLayout'))
  }, [
    layoutType,
    authenticated,
    propertyAuthenticated,
    location?.pathname,
    location.pathname === '/property',
    location?.pathname === `${ROUTES?.printInvoice}:id`,
    location?.pathname === `${ROUTES?.printBillingInvoice}:id`,
    location?.pathname === 'booking-engine-preview',
    // location?.pathname === `${ROUTES.getRoomKey}`,
    location?.pathname === '/get-room-key/',
    location?.pathname === '/print-report'
  ])

  return (
    <Suspense
      fallback={
        <div className="flex flex-auto flex-col h-[100vh] ">
          <Loading loading />
        </div>
      }
    >
      <AppLayout />
    </Suspense>
  )
}
export default Layout
