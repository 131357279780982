import { createSlice, PayloadAction, createAsyncThunk } from '@reduxjs/toolkit'
import axios from 'axios'
import { GET_ROOM_NUMBERS_FOR_UNIT } from './constants'
import appConfig from '@/configs/app.config'
import { getLocale } from '@/utils/locale'
import { getAccessToken } from '@/utils/accessToken'

export type PropertyRoomNumberForPropertyState = {
  currentRouteKey: string
  apiData: any[]
  status: 'idle' | 'loading' | 'succeeded' | 'failed'
  error: string | null
}

export const initialRoomNumberForPropertyState: PropertyRoomNumberForPropertyState =
  {
    currentRouteKey: '',
    apiData: [],
    status: 'idle',
    error: null
  }

// Define an async thunk for fetching API data
export const fetchRoomNumberForPropertyApiData = createAsyncThunk(
  `${GET_ROOM_NUMBERS_FOR_UNIT}/RoomNumberForProperty`,
  async (commaArray: any, thunkAPI) => {
    const accessToken = getAccessToken(thunkAPI)

    const config = {
      url: `room/get-rooms-number-for-units/?unit_id=${commaArray}`,
      method: 'get',
      baseURL: `${appConfig.propertyApiPrefix}`,
      headers: {
        'accept-language': getLocale(),
        'content-type': 'application/json',
        Authorization: `Bearer ${accessToken}`
      }
    }

    const response = await axios(config)
    return response.data
  }
)

export const RoomNumberForPropertySlice = createSlice({
  name: `${GET_ROOM_NUMBERS_FOR_UNIT}/RoomNumberForProperty`,
  initialState: initialRoomNumberForPropertyState,
  reducers: {
    setCurrentRoomNumberForPropertyRouteKey: (
      state,
      action: PayloadAction<string>
    ) => {
      state.currentRouteKey = action.payload
    }
  },
  extraReducers: builder => {
    builder
      .addCase(fetchRoomNumberForPropertyApiData.pending, state => {
        state.status = 'loading'
      })
      .addCase(fetchRoomNumberForPropertyApiData.fulfilled, (state, action) => {
        state.status = 'succeeded'
        state.apiData = action.payload
      })
      .addCase(fetchRoomNumberForPropertyApiData.rejected, (state, action) => {
        state.status = 'failed'
        state.error = action.error.message || null
      })
  }
})

export const { setCurrentRoomNumberForPropertyRouteKey } =
  RoomNumberForPropertySlice.actions

export default RoomNumberForPropertySlice.reducer
