import { combineReducers } from '@reduxjs/toolkit'
import propertyReduce, { PropertyState } from './property'
import propertyRoomReduce, { PropertyRoomState } from './propertyRoom'
import propertyTypeReduce, { PropertyTypeState } from './PropertyType'
import propertyRoomNumber, {
  PropertyRoomNumberState
} from './propertyRoomNumber'
import propertyUserReduce, { PropertyUserState } from './propertyUser'
import unitTypeReduce, { UnitTypeState } from './unitTypeSlice'
import amenitiesReduce, { amenitiesState } from './amenitiesSlice'
import propertyPolicy, { PropertyPolicyState } from './GetPropertyPolicy'
import propertyExtras, { PropertyExtrasState } from './ExtrasSlice'
import category, { CategoryState } from './CategoriesSlice'
import restrictionPlan, { RestrictionPlanState } from './GetRestrictionPlane'
import pricingPlan, { PricingPlanState } from './GetPricingPlane'
import promotion, { PromotionDataState } from './GetPromotionsSlice'
import getCancellationPolicy, {
  CancellationPolicyState
} from './GetCalcellationPolicy'
import getCancellationPolicyType, {
  CancellationPolicyTypeState
} from './GetCancellationPolicyType'
import roomNumberForFloor, {
  PropertyRoomNumberForFloorState
} from './RoomNumberForFloor'
import floorReduce, { PropertyFloorState } from './floorProperty'
import specialOffer, { SpecialDataState } from './GetSpecialOfferSlice'
import propertyUnitData, { UnitDataState } from './GetUnitPropertySlice'
import variationData, { VariationDataState } from './GetVariationSlice'
import roomsForProperty, {
  PropertyRoomForPropertyState
} from './roomForPropertySlice'
import roomNumberForProperty, {
  PropertyRoomNumberForPropertyState
} from './roomNumberForUnit'
import promtionTypeSlice, { PromotionTypeDataState } from './GetPromotionType'
import getDigitalKeys, {
  DigitalKeysState
} from './digitalKey/GetAllDigitalKeysSlice'
import getRoomNumbers, { RoomNumbersState } from './GetRoomNumbersSlice'
import getAllRooms, { GetAllRoomsDataState } from './GetAllRoomsSlice'
import getRoomNfcKey, { RoomNFCKeyState } from './GetNFCCardKey'
import getLostAndFound, { LostAndFoundState } from './LastAndFoundSlice'
import getMaintenance, {
  MaintenanceReportsState
} from './MaintenanceReportsSlice'
import getCalendarNotification, {
  GetCalendarNotificationState
} from './GetCalendarNotificationSlice'
import getAllNotification, {
  GetAllNotificationState
} from './GetAllNotificationSlice'
import getSetting, { GetCalendarSettingState } from './GetCalendarSettingSlice'
import getRoomOutOfService, {
  GetRoomOutOfServiceState
} from './GetRoomOutOfServiceSlice'
import getYeildCalendarVariation, {
  YeildCalendarVariationState
} from './GetYeildCalendarVariationsSlice'
import getRandomPasscode, {
  RandomPasscodeState
} from '@/store/slices/properties/digitalKey/GetRandomPasscodeSlice'

const reducer = combineReducers({
  getRandomPasscode,
  propertyReduce,
  propertyRoomReduce,
  propertyTypeReduce,
  propertyRoomNumber,
  propertyUserReduce,
  unitTypeReduce,
  amenitiesReduce,
  propertyPolicy,
  propertyExtras,
  category,
  restrictionPlan,
  pricingPlan,
  getCancellationPolicy,
  getCancellationPolicyType,
  floorReduce,
  promotion,
  specialOffer,
  propertyUnitData,
  variationData,
  roomNumberForFloor,
  roomsForProperty,
  roomNumberForProperty,
  promtionTypeSlice,
  getDigitalKeys,
  getRoomNumbers,
  getAllRooms,
  getRoomNfcKey,
  getLostAndFound,
  getMaintenance,
  getCalendarNotification,
  getAllNotification,
  getSetting,
  getRoomOutOfService,
  getYeildCalendarVariation
})

export type propertyManageState = {
  getRandomPasscode: RandomPasscodeState
  getDigitalKeys: DigitalKeysState
  propertyReduce: PropertyState
  propertyRoomReduce: PropertyRoomState
  propertyTypeReduce: PropertyTypeState
  propertyRoomNumber: PropertyRoomNumberState
  propertyUserReduce: PropertyUserState
  unitTypeReduce: UnitTypeState
  amenitiesReduce: amenitiesState
  propertyPolicy: PropertyPolicyState
  propertyExtras: PropertyExtrasState
  category: CategoryState
  restrictionPlan: RestrictionPlanState
  pricingPlan: PricingPlanState
  getCancellationPolicy: CancellationPolicyState
  getCancellationPolicyType: CancellationPolicyTypeState
  floorReduce: PropertyFloorState
  promotion: PromotionDataState
  specialOffer: SpecialDataState
  propertyUnitData: UnitDataState
  variationData: VariationDataState
  roomNumberForFloor: PropertyRoomNumberForFloorState
  roomsForProperty: PropertyRoomForPropertyState
  roomNumberForProperty: PropertyRoomNumberForPropertyState
  promtionTypeSlice: PromotionTypeDataState
  getRoomNumbers: RoomNumbersState
  getAllRooms: GetAllRoomsDataState
  getRoomNfcKey: RoomNFCKeyState
  getLostAndFound: LostAndFoundState
  getMaintenance: MaintenanceReportsState
  getCalendarNotification: GetCalendarNotificationState
  getAllNotification: GetAllNotificationState
  getSetting: GetCalendarSettingState
  getRoomOutOfService: GetRoomOutOfServiceState
  getYeildCalendarVariation: YeildCalendarVariationState
}

export * from './property'
export * from './propertyRoom'
export * from './PropertyType'
export * from './propertyRoomNumber'
export * from './propertyUser'
export * from './unitTypeSlice'
export * from './amenitiesSlice'
export * from './GetPropertyPolicy'
export * from './CategoriesSlice'
export * from './ExtrasSlice'
export * from './GetRestrictionPlane'
export * from './GetPricingPlane'
export * from './GetCalcellationPolicy'
export * from './GetCancellationPolicyType'
export * from './RoomNumberForFloor'
export * from './floorProperty'
export * from './GetPromotionsSlice'
export * from '../Reservation/GetBoards'
export * from './GetSpecialOfferSlice'
export * from './GetUnitPropertySlice'
export * from './GetVariationSlice'
export * from './roomForPropertySlice'
export * from './roomNumberForUnit'
export * from './GetPromotionType'
export * from './digitalKey/GetAllDigitalKeysSlice'
export * from './GetRoomNumbersSlice'
export * from './GetAllRoomsSlice'
export * from './GetNFCCardKey'
export * from './LastAndFoundSlice'
export * from './MaintenanceReportsSlice'
export * from './GetCalendarNotificationSlice'
export * from './GetAllNotificationSlice'
export * from './GetCalendarSettingSlice'
export * from './GetRoomOutOfServiceSlice'
export * from './GetYeildCalendarVariationsSlice'
export * from './digitalKey/GetRandomPasscodeSlice'

export default reducer
