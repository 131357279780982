import { createSlice } from '@reduxjs/toolkit'
import { BOOKING_ENGINE_GUEST } from '@/store/slices/Reservation/constant'
const guestUserData = localStorage.getItem('guestLogin')
let data: any = null // Define the variable to hold parsed data
if (guestUserData) {
  try {
    data = JSON.parse(guestUserData) // Parse the data from localStorage
  } catch (error) {
    console.error('Error parsing user data:', error)
  }
} else {
  console.log('User data not found in localStorage')
}
export type guestUserState = {
  guestUser: any
  authority: any
}
const initialState: guestUserState = {
  guestUser: data ? data : null,
  authority: null
}

const guestUserSlice = createSlice({
  name: `${BOOKING_ENGINE_GUEST}/bookingEngineGuest`,
  initialState,
  reducers: {
    setGuestUser(state, action) {
      state.guestUser = action.payload
    }
  }
})
export const { setGuestUser } = guestUserSlice.actions
export default guestUserSlice.reducer
