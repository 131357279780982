import { createSlice, PayloadAction, createAsyncThunk } from '@reduxjs/toolkit'
import axios from 'axios'
import { GET_CANCELLATION_POLICY_TYPE_BASE_NAME } from './constants'
import appConfig from '@/configs/app.config'
import { getLocale } from '@/utils/locale'
import { getAccessToken } from '@/utils/accessToken'

export type CancellationPolicyTypeState = {
  currentRouteKey: string
  apiData: any[]
  status: 'idle' | 'loading' | 'succeeded' | 'failed'
  error: string | null
}

export const initialCancellationPolicyTypeState: CancellationPolicyTypeState = {
  currentRouteKey: '',
  apiData: [],
  status: 'idle',
  error: null
}

// Define an async thunk for fetching API data
export const fetchCancellationPolicyTypeApiData = createAsyncThunk(
  `${GET_CANCELLATION_POLICY_TYPE_BASE_NAME}/fetchCancellationPolicyTypeApiData`,
  async (_, thunkAPI) => {
    const accessToken = getAccessToken(thunkAPI)

    const config = {
      url: `lookups/get-cancellation-policy_type/`,
      method: 'get',
      baseURL: `${appConfig.propertyApiPrefix}`,
      headers: {
        'accept-language': getLocale(),
        'content-type': 'application/json',
        Authorization: `Bearer ${accessToken}`
      }
    }

    const response = await axios(config)
    return response.data
  }
)

export const getCancellationPolicyTypeSlice = createSlice({
  name: `${GET_CANCELLATION_POLICY_TYPE_BASE_NAME}/getCancellationPolicyType`,
  initialState: initialCancellationPolicyTypeState,
  reducers: {
    setCurrentGetCancellationPolicyTypeRouteKey: (
      state,
      action: PayloadAction<string>
    ) => {
      state.currentRouteKey = action.payload
    }
  },
  extraReducers: builder => {
    builder
      .addCase(fetchCancellationPolicyTypeApiData.pending, state => {
        state.status = 'loading'
      })
      .addCase(
        fetchCancellationPolicyTypeApiData.fulfilled,
        (state, action) => {
          state.status = 'succeeded'
          state.apiData = action.payload
        }
      )
      .addCase(fetchCancellationPolicyTypeApiData.rejected, (state, action) => {
        state.status = 'failed'
        state.error = action.error.message || null
      })
  }
})

export const { setCurrentGetCancellationPolicyTypeRouteKey } =
  getCancellationPolicyTypeSlice.actions

export default getCancellationPolicyTypeSlice.reducer
