/*
Author: Azmatullah (azmatullah_jsdev_sc19@syncclouds.com)
Created: 11/09/2024, 00:27:35
Modified: 11/09/2024, 00:28:30

Description:
  This file contains the functions related to locales.
*/

export const getLocale = () => {
  const locale = localStorage?.getItem('i18nextLng') || 'en'

  return locale
}
